/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';

const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const DAO = () => {


	return (
		<div class="daoMainBg">
			<div class="daoMain">
				<div class="title">Our Mission</div>
				<p>We aim to empower tribal leaders and communities to achieve true financial independence. Supported by the Waitangi Tribunal Claim 861, we enable tribes to own and govern their Tribal Entities, ensuring that wealth generated from tribal trade, indigenous land rights, and modern financial services directly benefits the tribe's wealth and legacy.</p>
				</div>

		</div >
	)

}
export default DAO;
