/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';



const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const About = () => {


	return (
		<div class="aboutMain">
			<div class="title">About</div>
			<p>The Sovereign Bank of Aotearoa is at the forefront of a financial revolution, dedicated to fostering economic freedom and prosperity among indigenous tribes.</p>
			<p>Our innovative platform weaves the rich heritage of our ancestors with cutting-edge financial technologies like blockchain, smart contracts, and cryptocurrencies, into a system to carry and truly represent the wisdom of our elders into all generations to come.</p>

			<p></p>

			<iframe className='videoWidth' src="https://www.youtube.com/embed/1WRmSEc_oL0?si=5CAIRPsD6lLhyV5j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

		</div >
	)

}
export default About;
