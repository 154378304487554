/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';

const Why = () => {

	return (
		<div class="whyMain">
 
 
				<div className='content3Main'>
				<p>Unique Model of Governance</p>

				<div className='content3'>
					<div>
						Our distinctive approach places wealth stewardship in the hands of a carefully selected Council of Elders, or Taumata. This council oversees income from loans, transactions, sales, and services with unparalleled transparency and efficiency. We merge traditional values with modern technology, symbolized by our tribal flag that represents both a new beginning and the legal mandate of each Tribal Entity.
					</div>
				</div>
				</div>

				<div className='content3Main'>
				<p>Reinforcing Tribal Autonomy</p>

				<div className='content3'>
					<div>
						The Sovereign Bank of Aotearoa goes beyond financial services, reinforcing tribal autonomy and enabling leaders to shape their economic destiny.
					</div>

					<div>
						The Taumata ensures that leadership and strategic decisions align with the collective voice and best interests of the tribe.
					</div>
				</div>
				</div>

				<div className='content3Main'>
				<p>Join the Movement</p>

				<div className='content3'>
					<div>
						Embark on a journey with us to unlock unprecedented tribal trading, prosperity, and the stewardship of ancestral lands. The Sovereign Bank of Aotearoa invites you to be part of this transformative movement. Together, let's weave a future where the spirit of our ancestors and the promise of a thriving economy resonate in harmony, ushering in an era of abundance and freedom for generations to come.
					</div>
				</div>
				</div>


 		</div >
	)

}
export default Why;
