/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import logo from '../assets/Logo.png';

const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const Footer = () => {

	return (
		<footer class="footer">
			<div class="container">
				<img src={logo}/>
				<p>&copy; 2024 The Sovereign Bank of Aotearoa</p>
				<p>Join us at Ko Te Peeke O Aotearoa / The Sovereign Bank of Aotearoa and be part of the revolution. <br/> Secure your tribe's economic future today.</p>
			</div>
		</footer>

	)

}
export default Footer;
